function Sidebar() {
  return (
    <div>
      <section className="intro">
        <h1>Sunny Tian</h1>
        <p className="">ux designer who likes to code</p>
      </section>

        {/* <div className="links text-right">
          <div>
            <p><a href="mailto:sunnytianxin@gmail.com" rel="noreferrer">email &#8599;</a></p>
          </div>
          <div>
            <p><a href="https://www.linkedin.com/in/sunnyxtian/" target="_blank" rel="noreferrer">
              linkedin &#8599;
            </a></p>
          </div>
          <div>
            <p><a href="https://www.github.com/sunnyxtian/" target="_blank" rel="noreferrer">
              github &#8599;
            </a></p>
          </div>
        </div> */}
    </div>
  )
}

export default Sidebar;